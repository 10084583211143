import { Header } from "../../../../components/Auth/Admin/Header/Header";
import Select from "react-select";
import "./CrearPedidoCliente.css";
import { useState, useEffect } from "react";
import CardPedidoProduct from "../../../../components/Auth/Admin/CardPedidoProduct/CardPedidoProduct";
import CardPedidoCarrito from "../../../../components/Auth/Admin/CardPedidoCarrito/CardPedidoCarrito";
import Swal from "sweetalert2";

export const CrearPedidoCliente = () => {
  const styleSelectFilter = {
    control: (base, state) => ({
      ...base,
      background: "#944ce6e7",
      color: "white",
      border: "none",
    }),
    menu: (base) => ({
      ...base,
      color: "white",
      border: "none",
    }),
    menuList: (base) => ({
      ...base,
      color: "white",
      background: "#944ce6e7",
      width: "500px",
      border: "none",
      // "&:hover": {
      //   background: "red"
      // }
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "inherit" : "#944ce6e7",
      "&:hover": {
        background: "blue",
      },
    }),

    placeholder: (base) => {
      return {
        ...base,
        color: "#ffffff",
      };
    },
  };
  const [clientes, setClientes] = useState(null);
  const [clienteSeleccionado, seleccionarCliente] = useState(null);
  const [producto, setProducto] = useState(" ");
  const [dataProduct, setDataProduct] = useState(null);
  const [carritoData, setCarritoData] = useState([]);

  function getClientes() {
    fetch(`${process.env.REACT_APP_API}/portClientes.php`)
      .then((res) => res.json())
      .then((json) => {
        if (json.data.length > 0) {
          setClientes(json.data);
        }
      });
  }
  function getCarrito() {
    fetch(
      `${process.env.REACT_APP_API}/portCarrito.php?id_cliente=${
        clienteSeleccionado && clienteSeleccionado.value
      }`
    )
      .then((data) => data.json())
      .then((data) => {
        setCarritoData(data.data);
      });
  }

  function buscarProductos() {
    if (producto) {
      fetch(
        `${process.env.REACT_APP_API}/portBuscarProducto.php?idCliente=${
          clienteSeleccionado && clienteSeleccionado.value
        }&producto=${producto}`
      )
        .then((data) => data.json())
        .then((data) => {
          setDataProduct(data);
        });
    }
    // else{
    //   setDataProduct([])
    // }
  }

  function enviarPedido() {
    let url = `${
      process.env.REACT_APP_API
    }/portFinalizarPedido.php?id_cliente=${
      clienteSeleccionado && clienteSeleccionado.value
    }`;
    Swal.fire({
      title: "¿Estás seguro que quieres finalizar el pedido?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(url)
          .then((data) => data.json())
          .then((data) => {
            console.log(data);
            if (data === "Ok") {
              Swal.fire("Pedido enviado", "", "success");
              getCarrito()
              seleccionarCliente(null)
              
            } else {
              Swal.fire("Error al enviar", "", "error");
            }
          });
      }
    });
  }

  useEffect(() => {
    buscarProductos();
    // eslint-disable-next-line
  }, [producto, clienteSeleccionado]);

  useEffect(() => {
    getCarrito();
    // eslint-disable-next-line
  }, [clienteSeleccionado]);
  // useEffect(() => {
  //   getCarrito();
  //   // eslint-disable-next-line
  // });

  useEffect(() => {
    getClientes();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Header />
      <div className="container-buscar-cliente">
        <Select
          options={
            clientes &&
            clientes.map((client, index) => {
              return {
                label: client.RAZON_SOCIAL,
                value: client.ID,
                key: index,
              };
            })
          }
          
          className="inputAncho baseColorStyle "
          styles={styleSelectFilter}
          placeholder="Buscar cliente..."
          onChange={(cliente) => seleccionarCliente(cliente)}
          value={clienteSeleccionado}
        />

        <h3>
          Crear pedido para: {clienteSeleccionado && clienteSeleccionado.label}
        </h3>
      </div>
      <div className="container-crear-pedido-cliente">
        <div className="container-buscar-productos">
          <h3>BUSCAR PRODUCTOS</h3>
          <div className="container-buscador">
            <div className="search-container">
              <input
                className="inputSearch"
                type="text"
                onChange={(e) =>{ 
                  console.log(e.target.value.length)
                  if(e.target.value.length===0){
                    setProducto(" ")
                  }else{

                    setProducto(e.target.value)
                  }
                    
                }}
              />
              <svg viewBox="0 0 24 24" className="search__icon">
                <g>
                  <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                </g>
              </svg>
            </div>
          </div>

          <div className="content-products">
            {dataProduct &&
              dataProduct.map((dato) => (
                <CardPedidoProduct
                  nombre={dato.NOMBRE}
                  precio={dato.PRECIO}
                  id={dato.ID}
                  idCliente={clienteSeleccionado && clienteSeleccionado.value}
                  img={dato.img}
                  refresh={getCarrito}
                />
              ))}
          </div>
        </div>

        <div className="container-carrito-cliente">
          <h3>PEDIDO</h3>
          <div className="btn-enviar-pedido">
            {carritoData && (
              <button onClick={enviarPedido}>Enviar Pedido</button>
            )}
          </div>
          <div className="content-carrito">
            {carritoData &&
              carritoData.map((dato) => (
                <CardPedidoCarrito
                  nombre={dato.ARTICULO}
                  precio={dato.IMPORTE_TOTAL}
                  precioUnitario={dato.IMPORTE_UNITARIO}
                  cantidad={dato.CANTIDAD}
                  img={dato.img}
                  idLinea={dato.ID_LINEA}
                  idCliente={clienteSeleccionado && clienteSeleccionado.value}
                  refresh={getCarrito}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
