import { Header } from "../../../../components/Auth/Admin/Header/Header";
import { useState, useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import "./GestionFamilia.css";

export const GestionFamilia = () => {
  const [familiasOpts, setFamiliasOpts] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState(null);
  const [porcentaje, setPorcentaje] = useState(null);
  const [data, setData] = useState([]);

  function getDataCombos() {
    fetch(`${process.env.REACT_APP_API}/portFamilias.php`)
      .then((data) => data.json())
      .then((data) => {
        let familiadata = data.data;
        setData(familiadata);
        const familiasOptsMod = familiadata.map((item) => ({
          value: item.ID,
          label: item.NOMBRE,
        }));
        setFamiliasOpts(familiasOptsMod);
      });
  }

  useEffect(() => {
    getDataCombos();
  }, []);
  const styleSelectFilter = {
    control: (base, state) => ({
      ...base,
      background: "#944ce6e7",
      color: "white",
      border: "none",
    }),
    menu: (base) => ({
      ...base,
      color: "white",
      border: "none",
    }),
    menuList: (base) => ({
      ...base,
      color: "white",
      background: "#944ce6e7",
      width: "500px",
      border: "none",
      // "&:hover": {
      //   background: "red"
      // }
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "inherit" : "#944ce6e7",
      "&:hover": {
        background: "blue",
      },
    }),

    placeholder: (base) => {
      return {
        ...base,
        color: "#ffffff",
      };
    },
  };

  function enviarTarifaFamilia(e) {
    e.preventDefault();
    console.log(selectedFamilia);
    console.log(porcentaje);

    fetch(
      `${process.env.REACT_APP_API}/portActFamilias.php?id=${selectedFamilia}&porcentaje=${porcentaje}`
    )
      .then((data) => data.json())
      .then((data) => {
        if (data === "Ok") {
          Swal.fire({
            text: "¡Tarifa aplicada exitosamente!",
            type: "success",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          getDataCombos();
        } else {
          Swal.fire({
            text: "Error de servidor al aplicar tarifa",
            type: "error",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  }
  const handleSetPorcentaje = (porcentaje,id) => {
    setSelectedFamilia(id)
    setPorcentaje(porcentaje);
    console.log(familiasOpts[selectedFamilia])
  };
  return (
    <div>
      <Header />
      <div className="container-gestion-familias">
        <div className="container-tarifas">
          <h1>Gestión de Familias</h1>
        </div>
        <div className="creador-tarifas">
          <form onSubmit={enviarTarifaFamilia}>
            <div className="tarifa-block">
              <label htmlFor="nombre_familia">Familia</label>
              <Select
                options={familiasOpts && familiasOpts}
                className="inputAncho baseColorStyle "
                styles={styleSelectFilter}
                placeholder="Familia"
                // defaultValue={familiasOpts[selectedFamilia]}
                onChange={(choice) => {
                  setSelectedFamilia(choice.value);
                  const elementoEncontrado = data.find(
                    (item) => item.ID === choice.value
                  );
                  setPorcentaje(
                    elementoEncontrado.PORCENTAJE
                      ? elementoEncontrado.PORCENTAJE
                      : 0
                  );
                }}
                value={
                    !selectedFamilia
                      ? familiasOpts.filter(function (element) {
                          return element.value === selectedFamilia;
                        })
                      : familiasOpts.filter(function (element) {
                          return element.value === selectedFamilia;
                        })
                  }
              />
            </div>

            <div className="tarifa-block">
              <label htmlFor="porcentage_familia">Porcentaje %</label>

              <input
                type="number"
                name="porcentage_familia"
                id="porcentage_tarifa"
                value={porcentaje}
                step="0.10"
                onChange={(e) => {
                  setPorcentaje(e.target.value);
                }}
              />
            </div>
            <div className="tarifa-block">
              <label htmlFor="" style={{ opacity: 0 }}>
                Aplicar
              </label>
              <input type="submit" value="Aplicar" />
            </div>
          </form>
        </div>
        <div className="tarifas-creadas" style={{padding:"50px 100px"}}>
          {data &&
            data.map((dato, id) => (
              <TarifaFamiliaCard
                nombre={dato.NOMBRE}
                porcentage={dato.PORCENTAJE?dato.PORCENTAJE:0}
                id={dato.ID}
                key={dato.ID}
                onClick={handleSetPorcentaje}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const TarifaFamiliaCard = ({ nombre, porcentage, id,onClick }) => {
  function clickCard() {
   onClick(porcentage,id);
   console.log(id)
  }

  return (
    <div className="tarifa-card-container" onClick={clickCard}>
      <h3>{nombre}</h3>
      <h1>{porcentage}%</h1>
    </div>
  );
};
