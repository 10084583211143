import { createContext, useState } from "react";


export const DataContext = createContext();



export const DataProvider = ({children}) => {

    const [user,setUser] = useState(null)

    const [ tarifa,setTarifa] = useState(null)

    const [updateArticulos,setUpdateArticulos] = useState(false)

    const [datosArticulos,setDatosArticulos] = useState([])

     
    const [estadoArticuloIdContext,setEstadoArticuloIdContext] = useState("")
    const [proveedorContext,setProveedorContext] = useState("")
    return(
        <DataContext.Provider value={{userStatus:[user,setUser],tarifaEstado:[tarifa,setTarifa],estadoArticulos:[updateArticulos,setUpdateArticulos],estadoArticuloIdContextData:[estadoArticuloIdContext,setEstadoArticuloIdContext],proveedorContextFilter: [proveedorContext,setProveedorContext],
        datosArticulosGrid:[datosArticulos,setDatosArticulos]

        }}>
            {children}
        </DataContext.Provider>
    )
}