import { useNavigate } from "react-router-dom";
import{AiFillCloseCircle,AiFillCheckCircle,AiOutlineFileProtect,AiFillAlert} from "react-icons/ai"
import {MdPendingActions} from "react-icons/md"
import "./PedidoCard.css";

export const PedidoCard = ({ importe, fecha, estado, numPedido }) => {
  const navigate = useNavigate();

  function modificarPedido() {
    let userId = localStorage.getItem("user");
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(
      `${process.env.REACT_APP_API}/portModificarPedido.php?id_cliente=${userId}&id_pedido=${numPedido}`
    ).then((response) => {
      if (response.ok) {
        localStorage.setItem("id_pedido", numPedido);
        navigate(`/cliente/pedido`);
      }
    });
  }

  function colorEstado(estado) {
    if (estado === "Cancelado") {
      return "estado-cancelado";
    } else if (estado === "Aceptado") {
      return "estado-aceptado";
    } else if (estado === "Pendiente") {
      return"estado-pendiente"
    }else if (estado === "Modificado") {
      return"estado-modificado"
    }else if (estado === "Finalizado") {
      return"estado-finalizado"
    }
  }

  function iconoEstado(estado) {
    if (estado === "Cancelado") {
      return<AiFillCloseCircle style={{width:"100%",height:"100%",color:"#ff7c7cc7",fontWeight:"bolder"}}/>
    } 
    else if (estado === "Aceptado") {
      return <AiFillCheckCircle style={{width:"100%",height:"100%",color:"#96ff7cc7",fontWeight:"bolder"}}/>;
    } 
    else if (estado === "Pendiente") {
      return <MdPendingActions  style={{width:"100%",height:"100%",color:"#fff27cc7",fontWeight:"bolder"}}/>
    }else if (estado === "Modificado") {
      return<AiFillAlert style={{width:"100%",height:"100%",color:"#f07cffc7",fontWeight:"bolder"}}/>
    }else if(estado==="Finalizado"){
      return <AiOutlineFileProtect style={{width:"100%",height:"100%",color:"#2ef8fffa",fontWeight:"bolder",textShadow:"1px 2px 3px black !important"}}/>
    }
  }

  return (
    <div className="plan">
      <div className="inner">
        {/* <span className="pricing">
          <span>{importe}€</span>
        </span> */}
        <p className="title">Fecha {fecha} </p>
        <p className="info">
          Haga clic en en <b>Ver pedido</b> para ver los detalles de su pedido.
        </p>
        <ul className="features">
          <li>
            <span className="icon">
              {/* <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  fill="currentColor"
                  d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                ></path>
              </svg> */}
              {iconoEstado(estado)}
            </span>
            <span>
              <strong>Estado del pedido:</strong>{" "}
              <span className={`estado-color ${colorEstado(estado)}`}>
                {estado}
              </span>
            </span>
          </li>
        </ul>
        <div className="action">
          <button
            className="button"
            onClick={() => navigate(`/cliente/pedidos/detalle/${numPedido}`)}
          >
            Ver pedido
          </button>
        </div>
        <br />
        {estado === "Pendiente" ? (
          <div className="action">
            <button
              style={{ background: "orange" }}
              className="button"
              onClick={modificarPedido}
            >
              Modificar pedido
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
