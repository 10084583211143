import * as React from "react";
// import { FiMenu, FiUsers } from "react-icons/fi";
import { ImExit } from "react-icons/im";
import { MdAccountCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import Logo from "../../../assets/LogoColor2.png";
import "./HeaderProveedor.css";

import { DataContext } from "../../../context/DataContext";
import { useContext } from "react";

export const HeaderProveedor = () => {
  const { userStatus } = useContext(DataContext);
  // eslint-disable-next-line
  const [user, setUser] = userStatus;
  const navigate = useNavigate()
  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("user");
    return "/";
  };

  return (
    <div className="container-header-admin">
      <h2 style={{ width: "100px", color: "rgb(55, 5, 112)" }}>Proveedor</h2>
      <div className="logo-header">
        <img src={Logo} alt="" />
      </div>
      <div className="submenu-profile-prov">
        <MdAccountCircle onClick={()=>navigate("/proveedor/mi-cuenta")}/>
        <ImExit onClick={handleLogout} />
      </div>
    </div>
  );
};
