import "./Carrito.css";
import { Menu } from "../../../../components/Clientes/Menu/Menu";
import DataTable from "../../../../components/Auth/Admin/DataTable/DataTable";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {AiFillEdit} from "react-icons/ai"
import Swal from "sweetalert2";
export const Carrito = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
 
  const columnas = [
    // { field: "ID_LINEA", headerName: "ID", width: 90, height: 500 },
    {
      field: "Modificar",
      headerName: "Modificar",
      width: 100,
      height: 500,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <button
          
          onClick={() =>navigate(`/cliente/producto/${params.row.ID_PRODUCTO}/${params.row.CANTIDAD}`)}
        >
          <AiFillEdit style={{fontSize:"30px",color:"green"}}/>
        </button>
      ),
    },
    
    {
      field: "img",
      headerName: "Imagen Producto",
      width: 200,
      height: 500,
      renderCell: (params) => (
        <img
          style={{ width: "100%", height: "100%" }}
          src={params.value}
          alt=""
        />
      ),
    },


    { field: "ARTICULO", headerName: "Nombre", width: 320, height: 500 },

    {
      field: "IMPORTE_UNITARIO",
      headerName: "Importe unitario",
      width: 160,
      height: 500,
      renderCell: (params) => params.value + "€",
    },
    { field: "VARIEDAD", headerName: "Categoria", width: 120, height: 500 },
    { field: "CALIBRE", headerName: "Calibre", width: 120, height: 500 },

    {
      field: "CANTIDAD",
      headerName: "Cantidad en bultos",
      type: "number",
      width: 200,
      height: 500,
      editable: false,
      headerAlign:"center",
      renderCell: (params) => {
        return <span style={{width:"100%",height:"100%",cursor:"pointer",gap:"4px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            {params.value}
          </span>;
      },

    },
    {
      field: "Eliminar",
      headerName: "Eliminar",
      width: 100,
      height: 500,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <button
          style={{
            background: "red",
            color: "white",
            padding: "5px 10px",
            borderRadius: "40px",
          }}
          onClick={() => borrarArticulo(params.row.ID)}
        >
          X
        </button>
      ),
    },
    
  ];

  function borrarArticulo(id) {
    const ID_PEDIDO_MOD = localStorage.getItem("id_pedido");
    let url;
    if (ID_PEDIDO_MOD && data.length === 1) {
      url = `${process.env.REACT_APP_API}/portDelArticuloPedido.php?id_linea=${id}&id_pedido=${ID_PEDIDO_MOD}`;
    } else {
      url = `${process.env.REACT_APP_API}/portDelArticuloPedido.php?id_linea=${id}`;
    }
    fetch(url).then(() => getData());
  }
  function getData() {
    let userId = localStorage.getItem("user");
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(`${process.env.REACT_APP_API}/portCarrito.php?id_cliente=${userId}`)
      .then((response) => response.json())
      .then((response) => {
        setData(response.data);
        if (response.data === null) {
          localStorage.removeItem("id_pedido");
        }
      });
  }
  function finalizarPedido() {
    let userId = localStorage.getItem("user");
    userId = JSON.parse(userId);
    userId = userId[0].ID;

    let ID_PEDIDO_MODIFICADO = localStorage.getItem("id_pedido");
    var url;

    if (!ID_PEDIDO_MODIFICADO) {
      url = `${process.env.REACT_APP_API}/portFinalizarPedido.php?id_cliente=${userId}`;
    } else {
      url = `${process.env.REACT_APP_API}/portFinalizarPedido.php?id_cliente=${userId}&id_pedido=${ID_PEDIDO_MODIFICADO}`;
    }
    Swal.fire({
      title: "¿Seguro que quieres finalizar el pedido?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(url)
          .then((data) => data.json())
          .then((data) => {
            if (data === "Ok") {
              Swal.fire({
                text: "¡Pedido enviado exitosamente!",
                type: "success",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });
              localStorage.removeItem("id_pedido");
              navigate("/cliente/pedidos");
            } else {
              alert("error");
            }
          });
      }
    });
  }

  
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="">
      <Menu ruta="carrito" />

      <div className="container-carrito-grid">
        <DataTable
          datos={data && data}
          columnas={columnas}
          alturaCelda={120}
          altura={"70vh"}
          contenedor={600}
        />
      </div>
      {data && (
        <div className="finalizar-pedido">
          <button onClick={finalizarPedido}>FINALIZAR PEDIDO</button>
        </div>
      )}
    </div>
  );
};
