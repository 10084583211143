import { Link } from 'react-router-dom';
import { MdHome, MdSearch, MdShoppingCart, MdPerson } from 'react-icons/md';
import {IoExit} from "react-icons/io5"
import { DataContext } from '../../../context/DataContext';
import { useContext } from 'react';
import Logo from "../../../assets/cerdanLogoUpScale.png"
import './Menu.css';

export const Menu = ({ruta}) => {

    const { userStatus } = useContext(DataContext);
  // eslint-disable-next-line
  const [user, setUser] = userStatus;


  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("user");
    return "/"

  };
  return (
   
      <div className="header" id="header">
        <nav className="navbar container">
          <Link to="/" className="brand">
            <img src={Logo} alt="" style={{width:"150px"}} />
          </Link>
          <div className="menu" id="menu">
            <ul className="menu-list">
              <li className="menu-item">
                <Link to="/" className={(ruta==="cliente"?"menu-link is-active":"menu-link")}>
                  <MdHome className="menu-icon" />
                  <span className="menu-name">Inicio</span>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/cliente/pedidos" className={(ruta==="pedidos"?"menu-link is-active":"menu-link")}>
                  <MdSearch className="menu-icon" />
                  <span className="menu-name">Mis Pedidos</span>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/cliente/pedido" className={(ruta==="carrito"?"menu-link is-active":"menu-link")}>
                  <MdShoppingCart className="menu-icon" />
                  <span className="menu-name">Pedido</span>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/cliente/mi-cuenta" className={(ruta==="mi-cuenta"?"menu-link is-active":"menu-link")}>
                  <MdPerson className="menu-icon" />
                  <span className="menu-name">Mi cuenta</span>
                </Link>
              </li>
              <li className="menu-item" onClick={()=>handleLogout()} style={{cursor:"pointer"}}>
                
                  <IoExit className="menu-icon" />
                  <span className="menu-name">Salir</span>
                
              </li>
            </ul>
          </div>
        </nav>
      </div>
   
  );
};
