import "./InicioCliente.css";
import { Menu } from "../../../../components/Clientes/Menu/Menu";
import { OfertaCard } from "../../../../components/Clientes/OfertaCard/OfertaCard";
import { ProductoCard } from "../../../../components/Clientes/ProductoCard/ProductoCard";
import { InfoCard } from "../../../../components/Clientes/InfoCard/InfoCard";
import { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../../context/DataContext";
import { useParams } from "react-router-dom";


export const InicioCliente = () => {
  const [productos, setProductos] = useState([]);
  const navigate = useNavigate();
  const { userStatus } = useContext(DataContext);

  // eslint-disable-next-line
  const [user, setUser] = userStatus;

  let {hash} = useParams()
  function accesHash(){
   
    if(hash){
      //hacemos la petición y validamos si el hash es valido, si es válido guardamos en localSotrage el usuario
      //En caso de no ser válido le redirige a la ruta raiz automáticamente.

      fetch(`${process.env.REACT_APP_API}/login.php?ident=${hash}`)
        .then((data) => data.json())
        .then((data) => {
          let userData = data.data;
          if (userData[0].ERROR) {
            navigate("/")
            
          } else {
            localStorage.setItem("user", JSON.stringify(userData));
            setUser(userData);
            navigate("/cliente")
            window.location.reload()
            
          }
        });
      


    }
  }

  function getDataApi() {
    let userId = localStorage.getItem("user");
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(`${process.env.REACT_APP_API}/portArticulos.php?estado=3&idCliente=${userId}`)
      .then((res) => res.json())
      .then((json) => {
        setProductos(json.data);
      });
  }

  useEffect(()=>{
    accesHash()
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    getDataApi();
  }, []);

  // async function getNotification() {
  //   let userId = localStorage.getItem("user");
  //   userId = JSON.parse(userId);
  //   userId = userId[0].ID;
  //   let term = "1234";
  //   await fetch(`${process.env.REACT_APP_NODE_API}/message`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: JSON.stringify({
  //       message: term,
  //       userId: userId,
  //     }),
  //   });
  // }
  // useEffect(() => {
  //   getNotification();
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(()=>{
  // },[])
  return (
    <div className="">
      <Menu ruta="cliente" />
      <div className="inicio-cliente-container">
        <OfertaCard />
        <div className="nuestras-novedades">
          <h1>Nuestras Novedades</h1>
          <div className="novedades-productos">
            {productos &&
              productos.map((product) => {
                return (
                  <ProductoCard
                    imagen={product.img}
                    titulo={product.NOMBRE}
                    precio={product.PRECIO}
                    blink={product.BLINK}
                    codigo={product.CODIGO}
                    key={product.ID}
                    id={product.ID}
                  />
                );
              })}
          </div>
        </div>

        <section className="section parallax" id="seccion1">
          <div className="contenedor">
            <h1>comprometidos con la calidad</h1>
          </div>
        </section>

        <div className="info-cards">
          <InfoCard
            titulo={"¿Necesitas asistencia?"}
            contenido={
              "No dudes en ponerte en contacto con nosotros +34-999-999-999."
            }
          />
          <InfoCard
            titulo={"Envíos rápidos en 24h"}
            contenido={"Envíos en 1 día laborable en toda españa."}
          />
          <InfoCard
            titulo={"Conoce nuestro catálogo"}
            contenido={
              "Entra a nuestros catálogo y explora toda nuestra variedad."
            }
          />
        </div>
        <div className="contenido"></div>

        {/* <Footer/> */}
      </div>
    </div>
  );
};
