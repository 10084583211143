import { Header } from "../../../../components/Auth/Admin/Header/Header";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "../../../../components/Auth/Admin/Form/Form";

import "./GestionClientes.css";
import { useState, useEffect } from "react";

export const GestionClientes = () => {
  const [data, setData] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [datosRow, setDatosRow] = useState(null);

  const columnas = [
    {
      field: "ID",
      headerName: "ID",
      width: 90,
      cellClassName: "hide-cell",
      headerClassName: "hide-cell",
    },
    { field: "CODIGO", headerName: "Código", width: 100, editable: false },
    {
      field: "RAZON_SOCIAL",
      headerName: "Razón social",
      width: 300,
      editable: false,
      
    },
    {
      field: "EMAIL",
      headerName: "Email",
      width: 250,
      editable: false,
      
    },
   
    {
      field: "NIF",
      headerName: "NIF",
      width: 140,
      editable: false,
      
    },
    {
      field: "DIRECCION",
      headerName: "Direccion",
      width: 350,
      editable: false,
    },
    // { field: "EMAIL", headerName: "Email", width: 220, editable: false },
    {
      field: "PASSWORD",
      headerName: "Contraseña",
      width: 150,
      editable: false,
      cellClassName: "hide-cell",
      headerClassName: "hide-cell",
    },
    // {
    //   field: "PROVINCIA",
    //   headerName: "Provincia",
    //   width: 120,
    //   editable: false,
    // },
    {
      field: "POBLACION",
      headerName: "Población",
      width: 300,
      editable: false,
    },
    // { field: "TELEFONO1", headerName: "Teléfono", width: 150, editable: false },
    {
      field: "TELEFONO1",
      headerName: "Teléfono 1",
      width: 200,
      editable: false,
      
    },
   
    {
      field: "CPOSTAL",
      headerName: "C.Postal",
      width: 150,
      editable: false,
     
    },
    {
      field: "WEB",
      headerName: "Web",
      width: 150,
      editable: false,
      
    },
    {
      field: "CONTACTO",
      headerName: "Contacto",
      width: 150,
      editable: false,
      
    },
  
  ];

  function getDataApi() {
    fetch(`${process.env.REACT_APP_API}/portClientes.php`)
      .then((res) => res.json())
      .then((json) => {
        if (json.data.length > 0) {
          setData(json.data);
        }
      });
  }

  useEffect(() => {
    getDataApi();
  }, [openForm]);

  function handleOpenForm() {
    setDatosRow(null);
    setOpenForm(true);
  }
  function handleOpenFormParams(params) {
    setOpenForm(true);
    setDatosRow(params.row);
  }

  function handleCloseForm() {
    setOpenForm(false);
    getDataApi()
  }

  return (
    <div>
      <Header />
      <div style={{ height: 350, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columnas}
          getRowId={(row) => row.ID}
          onRowClick={handleOpenFormParams}
          style={{ height: "90vh" }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                CONTACTO: false,
                RAZON_SOCIAL: true,
                WEB:false,
                CPOSTAL:false,
                TELEFONO2:true,
                ID:false,
                PASSWORD:false,
                NIF:false
              },
            },
          }}
        />
      </div>

      <div className="flex-center">
        <div></div>
        <button className="buttonAltaCliente" onClick={handleOpenForm}>
          Alta nuevo cliente
        </button>
      </div>
      {openForm && (
        <div className="alta-container">
          
          <Form
            titulo={"Alta clientes"}
            msg={"Crear cuenta para un cliente"}
            tipoUsuario={"Clientes"}
            rowDataClick={datosRow}
            onCloseForm={handleCloseForm}
          />
        </div>
      )}
    </div>
  );
};
