
import "./InfoCard.css";

export const InfoCard = ({titulo,contenido}) => {
  return (
    <div className="info-card-container">
      
      <span>{titulo}</span>
      <p>{contenido}</p>
    </div>
  );
};
