import { Header } from "../../../../components/Auth/Admin/Header/Header";
import { useForm } from "react-hook-form";
import { DataContext } from "../../../../context/DataContext";
import "./Tarifas.css";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TarifaCard = ({ nombre, porcentage, logistica, id,referencia,vencimiento }) => {
  const { tarifaEstado } = useContext(DataContext);
  // eslint-disable-next-line
  const [tarifa, setTarifa] = tarifaEstado;

  const navigate = useNavigate();

  function clickCard() {
    navigate(
      `/admin/tarifas-actualizar/${nombre}/${porcentage}/${logistica}/${id}/${vencimiento}`
    );
  }

  return (
    <div className="tarifa-card-container" onClick={clickCard}>
      <h3>{nombre}</h3>
      <h1>{porcentage}%</h1>
      <span>Logística: {logistica}€</span>
      <b style={{padding:"4px 0px"}}>{referencia}</b>
    </div>
  );
};

export const Tarifas = () => {
  const { tarifaEstado } = useContext(DataContext);
  // eslint-disable-next-line
  const [tarifa, setTarifa] = tarifaEstado;
  const [tarifas, setTarifas] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [esVariable,setEsVariable] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function handleTarifaSubmit(data) {
    fetch(`${process.env.REACT_APP_API}/portActTarifas.php`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data === "Ok") {
          setReloadData(!reloadData ? true : false);
          getTarifas();
        }
      });
      
    setEsVariable(false)
    reset();
    setTarifa(null);
    setReloadData(!reloadData ? true : false);
    getTarifas();
  }

  function getTarifas() {
    fetch(`${process.env.REACT_APP_API}/portTarifas.php`)
      .then((data) => data.json())
      .then((data) => {
        setTarifas(data.data);
      });
  }

  useEffect(() => {
    getTarifas();
  }, [reloadData]);

  return (
    <div>
      <Header />

      <div className="container-tarifas">
        <h1>Gestión de tarifas</h1>
      </div>
      <div className="creador-tarifas">
        <form onSubmit={handleSubmit(handleTarifaSubmit)}>
          <div className="tarifa-block">
            <label htmlFor="variable">Es variable</label>
            <input
              type="checkbox"
              name="variable"
              id="variable"
              onClick={(e)=>setEsVariable(e.target.checked)}
              style={{
                width: "100px",
                height: "30px",
              }}
              {...register("variable", {
                required: false,
              })}
            />
          </div>
          <div className="tarifa-block">
            <label htmlFor="nombre_tarifa">Nombre tarifa</label>
            {errors.nombre_tarifa?.type === "required" && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="text"
              name="nombre_tarifa"
              id="nombre_tarifa"
              {...register("nombre_tarifa", {
                required: true,
              })}
            />
          </div>

          <div className="tarifa-block">
            <label htmlFor="porcentage_tarifa">Porcentaje %</label>
            {errors.porcentage_tarifa?.type === "required" && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="number"
              name="porcentage_tarifa"
              id="porcentage_tarifa"
              disabled={esVariable}
              // onChange={() => alert("s")}
              {...register("porcentage_tarifa", {
                required: !esVariable,
              })}
            />
          </div>

          <div className="tarifa-block">
            <label htmlFor="logistica">Logística (€)</label>
            {errors.logistica?.type === "required" && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="number"
              name="logistica"
              id="logistica"
              onChange={() => alert("s")}
              {...register("logistica", {
                required: true,
              })}
            />
          </div>
          <div className="tarifa-block">
            <label htmlFor="vencimiento">Vencimiento</label>
            {errors.logistica?.type === "required" && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="datetime-local"
              name="vencimiento"
              id="vencimiento"
              // onChange={() => alert("s")}
              {...register("vencimiento", {
                required: true,
              })}
            />
          </div>

          <div className="tarifa-block">
            <label htmlFor="" style={{ opacity: 0 }}>
              crear
            </label>
            <input type="submit" value="Crear tarifa" />
          </div>
        </form>
      </div>

      <div className="tarifas-creadas">
        {tarifas &&
          tarifas.map((dato, id) => (
            <TarifaCard
              nombre={dato.NOMBRE}
              porcentage={dato.PORCENTAGE}
              logistica={dato.LOGISTICA}
              referencia={dato.REF}
              id={dato.ID}
              vencimiento={dato.VENCIMIENTO}
              key={id}
            />
          ))}
      </div>
    </div>
  );
};
