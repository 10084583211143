import { Header } from "../../../../components/Auth/Admin/Header/Header";
import { useForm } from "react-hook-form";
import { DataContext } from "../../../../context/DataContext";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./GestionGrupoClientes.css";

const GrupoCard = ({ nombre, nombreTarifa, tarifaName,id }) => {
  const { tarifaEstado } = useContext(DataContext);
  // eslint-disable-next-line
  const [tarifa, setTarifa] = tarifaEstado;

  const navigate = useNavigate();

  function clickCard() {
    navigate(`/admin/grupos-actualizar/${nombre}/${nombreTarifa}/${id}`);
  }

  return (
    <div className="tarifa-card-container" onClick={clickCard}>
      <h3>{nombre}</h3>
      <span>-</span>
      <h4>Tarifa: <br></br>{tarifaName}</h4>
    </div>
  );
};

export const GestionGrupoClientes = () => {
  const { tarifaEstado } = useContext(DataContext);
  // eslint-disable-next-line
  const [tarifa, setTarifa] = tarifaEstado;
  const [tarifas, setTarifas] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function handleGrupoSubmit(data) {
   
    fetch(`${process.env.REACT_APP_API}/portActGruposClientes.php`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    }).then((data) => data.json());

    reset();
    setTarifa(null);

    setReloadData(!reloadData ? true : false);
    getTarifas();
    getGrupos()
  }

  function getTarifas() {
    fetch(`${process.env.REACT_APP_API}/portTarifas.php`)
      .then((data) => data.json())
      .then((data) => {
        setTarifas(data.data);
      });
  }
  function getGrupos() {
    fetch(`${process.env.REACT_APP_API}/portGruposClientes.php`)
      .then((data) => data.json())
      .then((data) => {
        setGrupos(data.data);
      });
  }

  useEffect(() => {
    getTarifas();
    getGrupos();
  }, [reloadData]);

  return (
    <div>
      <Header />

      <div className="container-tarifas">
        <h1>Gestión grupos de clientes</h1>
      </div>
      <div className="creador-tarifas">
        <form onSubmit={handleSubmit(handleGrupoSubmit)}>
          <div className="tarifa-block">
            <label htmlFor="nombre">Nombre grupo</label>
            {errors.nombre?.type === "required" && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="text"
              name="nombre"
              id="nombre_tarifa"
              {...register("nombre", {
                required: true,
              })}
            />
          </div>

          <div className="tarifa-block">
            <label htmlFor="id_tarifa">Tarifas</label>
            <select
              name="id_tarifa"
              {...register("id_tarifa", {
                required: true,
              })}
            >
              {tarifas &&
                tarifas.map((dato, index) => {
                  return (
                    <option value={dato.ID} key={index}>
                      {dato.NOMBRE}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="tarifa-block">
            <label htmlFor="" style={{ opacity: 0 }}>
              crear
            </label>
            <input type="submit" value="Crear grupo" />
          </div>
        </form>
      </div>

      <div className="tarifas-creadas">
        {grupos &&
          grupos.map((dato, index) => (
            <GrupoCard
              nombre={dato.NOMBRE}
              nombreTarifa={dato.ID_TARIFA}
              id={dato.ID}
              key={index}
              tarifaName={dato.NOMBRE_TARIFA}
            />
          ))}
      </div>
    </div>
  );
};
