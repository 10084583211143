import { useState } from "react";
import Swal from "sweetalert2";
export default function CardPedidoProduct({
  img,
  nombre,
  precio,
  id,
  idCliente,
  refresh,
}) {
  const [cantidad, setCantidad] = useState(1);

  function añadir() {
    if (!idCliente) {
      Swal.fire("Debe de seleccionar primero un cliente.");
    } else {
      fetch(
        `${process.env.REACT_APP_API}/portAddArticuloPedido.php?id_producto=${id}&cantidad=${cantidad}&id_cliente=${idCliente}`
      )
        .then((data) => data.json())
        .then((data) => {
          if (data === "Ok") {
            Swal.fire({
              position: "bottom-start",
              icon: "success",
              title: "Añadido correctamente",
              showConfirmButton: false,
              timer: 1500,
            });
            refresh();
          }
        });
    }
  }

  return (
    <div className="card-prod-search">
      <img src={img} alt="" />
      <div className="card-search-content">
        <h2>{nombre}</h2>
        <h3>{precio}€</h3>
        <label htmlFor="">Cantidad</label>
        <div class="number-control">
          <div
            class="number-left"
            onClick={() => setCantidad(cantidad > 0 && cantidad - 1)}
          ></div>
          <input
            type="number"
            min={1}
            name="number"
            className="number-quantity"
            //   onChange={(e) => setCantidad(e.target.value)}
            onChange={(e) => setCantidad(parseInt(e.target.value))}
            value={cantidad}
          />
          <div
            class="number-right"
            onClick={() => setCantidad(cantidad + 1)}
          ></div>
        </div>
        <button className="btn-add" onClick={() => añadir(nombre, precio, id)}>
          Añadir
        </button>
      </div>
    </div>
  );
}
