import { DataGrid } from "@mui/x-data-grid";
import { Menu } from "../../../../components/Clientes/Menu/Menu";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import "./PedidosDetalle.css";

export const PedidosDetalles = () => {
  const { codigo } = useParams();
  const [data, setData] = useState([]);
  const columns = [
    { field: "ID", headerName: "ID", width: 90, height: 500 },
    {
      field: "img",
      headerName: "Imagen Producto",
      width: 200,
      height: 500,
      renderCell: (params) => (
        <img
          style={{ width: "100%", height: "100%" }}
          src={params.value}
          alt=""
        />
      ),
    },

    { field: "ARTICULO", headerName: "Nombre", width: 300, height: 500 },
    { field: "VARIEDAD", headerName: "Categoria", width: 120, height: 500 },
    { field: "CALIBRE", headerName: "Calibre", width: 120, height: 500 },

    {
      field: "CANTIDAD",
      headerName: "Cantidad",
      width: 100,
      height: 500,
      renderCell: (params) => params.value + "/"+params.row.UNIDAD_VENTA,
    },
    {
      field: "IMPORTE_UNITARIO",
      headerName: "Precio unitario",
      width: 160,
      height: 500,
      renderCell: (params) => params.value + "€",
    },
    // {
    //   field: "IMPORTE_TOTAL",
    //   headerName: "Importe total",
    //   width: 160,
    //   height: 500,
    //   renderCell: (params) => params.value + "€",
    // },

   
   
  ];

  function getDatosPedido(){
    let userId = localStorage.getItem("user");
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(`${process.env.REACT_APP_API}/portDatosPedido.php?id_cliente=${userId}&id_pedido=${codigo}`)
      .then((response) => response.json())
      .then((response) => {
        setData(response.data);
      });
  }
 

  useEffect(()=>{
    getDatosPedido()
    // eslint-disable-next-line
  },[])
  return (
    <div>
      <Menu ruta={"pedidos"}/>
      <div className="container-pedidos-detalle">
        <div className="detalles-pedido">
          <h2>Detalles del pedido</h2>
          {/* <ul>
            <li>
              <span>Número de pedido:</span> #{codigo}
            </li>
            <li>
              <span>Dirección de entrega:</span> Charles Robert Darwin 20
            </li>
            <li>
              <span>Fecha de pedido:</span> 29/09/2023 9:08{" "}
            </li>
            <li>
              <span>Gastos de envío: </span> 12,30€
            </li>
            <li>
              <span>Subtotal: </span> 320,89€
            </li>
            <li>
              <span>IVA:</span> 34,95€
            </li>
            <li>
              <span>Total:</span> 420,30€
            </li>
            <li>
              <span>Ver factura: </span>
              <button className="buttonDownload"> Descargar</button>
            </li>
          </ul> */}
        </div>
        <div className="grid-detalles-pedido">
          <DataGrid
            rows={data&&data}
            columns={columns}
            getRowId={(row) => row.ID}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 7,
                },
              },
              columns: {
                columnVisibilityModel: {
                  ID: false,
                  REFERENCIA_CLIENTE:false,
                  ID_GRUPO:false
                },
              },
            }}
           
            pageSizeOptions={[7]}

            disableRowSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
};
