import Logo from "../../assets/LogoColor2.png";
import {  useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useState, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import serviceWorker from "../../serviceWorker"
import "./Login.css";

export default function Login() {
  const navigate = useNavigate();
  const { userStatus } = useContext(DataContext);

  // eslint-disable-next-line
  const [user, setUser] = userStatus;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [typePass, setTypePass] = useState(false);
  const changeTypePassword = () => {
    setTypePass(typePass ? false : true);
  };

  const handleLogin = (data) => {
    fetch(`${process.env.REACT_APP_API}/login.php`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        let userData = data.data;
        if (userData[0].ERROR) {
          Swal.fire({
            icon: "error",
            title: "Error de inicio de sesión",
            text: "Por favor, vuelva a intentarlo.",
            confirmButtonColor: '#333',
            
          });
          
        } else {
          localStorage.setItem("user", JSON.stringify(userData));
          setUser(userData);
          serviceWorker()
          if(userData[0].ID_PERFIL===1){
            navigate("/admin");
          }else if(userData[0].ID_PERFIL===2){
            navigate("/cliente");
          }else if(userData[0].ID_PERFIL===3){
            navigate("/proveedor");
          }
        }
      });
  };

  return (
    <div className="login-container">
      <form className="form-container" onSubmit={handleSubmit(handleLogin)}>
        <div className="form-header">
          <img loading="lazy" src={Logo} alt="" />
          <span>Servicio de intranet Frutas Cerdan</span>
        </div>
        <div className="input-row">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            {...register("email", {
              required: true,
              maxLength: 30,
              // pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
            })}
          />
          {errors.email?.type === "required" && (
            <p className="error">El email es requerido.</p>
          )}
          {errors.email?.type === "pattern" && (
            <p className="error">El formato del email es incorrecto.</p>
          )}
        </div>
        <div className="input-row">
          <label htmlFor="password">Contraseña</label>
          <div style={{ display: "flex" }}>
            <input
              type={typePass ? "text" : "password"}
              name="password"
              id="password"
              {...register("password", {
                required: true,
              })}
            />
            <span className="eyeBtn" onClick={changeTypePassword}>
              {typePass ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </span>
          </div>

          {errors.password?.type === "required" && (
            <p className="error">La contraseña es requerida.</p>
          )}
          {/* <Link
            to="/register"
            style={{
              textDecoration: "none",
              color: "grey",
              marginTop: "10px",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            ¿No tienes una cuenta creada? Registrate
          </Link> */}
        </div>
        <div className="input-row-submit">
          <input type="submit" value="Entrar" />
        </div>
      </form>
    </div>
  );
}
