// import { Link } from "react-router-dom"
import { useState,useEffect } from "react"
import "./OfertaCard.css"


export const OfertaCard = ( )=> {
    const [datos,setDatos] = useState([])


    const getDataOffer = () => {
        fetch(`${process.env.REACT_APP_API}/portOferta.php`)
        .then(data=>data.json())
        .then(data=>setDatos(data))
    }


    useEffect(()=>{
        getDataOffer()
    },[])
    
    return(
        <div className="oferta-card-container">
            <div className="titulo-oferta">
                <h3>{datos.titulo}</h3>
                {/* <Link to={ `./producto/${datos.id_articulo}`}>Comprar Ya</Link> */}
            </div>
            <div className="image-oferta">
                <img src={datos.imagen} alt="" />
            </div>
        </div>
    )
}