import { useNavigate } from "react-router-dom"
import {MdLocalOffer} from "react-icons/md"
import "./ProductoCard.css"
import { useEffect, useState } from "react"

export const ProductoCard = ({imagen,titulo,precio,blink,id}) => {
    const [blinkState,setBlink] = useState(blink?blink:null)
    const navigate = useNavigate()


    function navigateToProduct(){
        navigate(`/cliente/producto/${id}`)
    }

    useEffect(()=>{
        setBlink(parseInt(blink))
        
    },[blink])

    return(
        <div className={blinkState===1?"product-card-container neon-card":"product-card-container"} onClick={navigateToProduct}>
            <div className="image-card-product">
                <img src={imagen} alt="producto" />
            </div>
            <div className="datos-product">
                <span>{titulo}</span>
                <span className="product-price">{precio}€/kg</span>
                {/* <button>Añadir al carrito</button> */}
            </div>

            {
                blinkState===1? <div className="oferta-especial"><h4 style={{display:"flex",justifyContent:"left",alignItems:"center"}}>¡Oferta especial!<MdLocalOffer style={{marginLeft:"5px"}}/></h4></div>:""
            }
        </div>
    )
}