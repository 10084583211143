import { DataContext } from "./context/DataContext";
import { Routes, Route,Navigate,   } from "react-router-dom";
import { useEffect,useContext, useState } from "react";
//===================Pages========================
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register"
import { Admin } from "./pages/Auth/Admin/Admin";
import { Usuarios } from "./pages/Auth/Admin/Usuarios/Usuarios";
import { Articulos } from "./pages/Auth/Admin/Articulos/Articulos";
import { CrearArticulos } from "./pages/Auth/Admin/CrearArticulos/CrearArticulos";
import { Error404 } from "./pages/Error404/Error404";
import { InicioCliente } from "./pages/Auth/Clientes/InicioCliente/InicioCliente";
import { Catalogo } from "./pages/Auth/Clientes/Catalogo/Catalogo";
import { Carrito } from "./pages/Auth/Clientes/Carrito/Carrito";
import { CrearOferta } from "./pages/Auth/Admin/CrearOferta/CrearOferta";
import { Producto } from "./pages/Auth/Clientes/Producto/Producto";
import { Tarifas } from "./pages/Auth/Admin/Tarifas/Tarifas";
import { TarifasActualizar } from "./pages/Auth/Admin/TarifasActualizar/TarifasActualizar";
import { Proveedor } from "./pages/Auth/Proveedor/Proveedor";
import { MiCuentaProveedor } from "./pages/Auth/Proveedor/MiCuentaProveedor/MiCuentaProveedor";
import { GestionClientes } from "./pages/Auth/Admin/GestionClientes/GestionClientes";
import { MiCuenta } from "./pages/Auth/Clientes/MiCuenta/MiCuenta";
import { GestionGrupoClientes } from "./pages/Auth/Admin/GestionGrupoClientes/GestionGrupoClientes";
import { GestionGrupoClientesActualizar } from "./pages/Auth/Admin/GestionGruposClientesActualizar/GestionGruposClientesActualizar";
import { ThemeProvider, createTheme } from '@mui/material/styles'; 
import { MisPedidos } from "./pages/Auth/Clientes/MisPedidos/MisPedidos";
import { PedidosDetalles } from "./pages/Auth/Clientes/PedidosDetalle/PedidosDetalle";
import { Pedidos } from "./pages/Auth/Admin/Pedidos/Pedidos";
import { DetallesPedido } from "./pages/Auth/Admin/DetallesPedido/DetallesPedido";
import { GestionPedidos } from "./pages/Auth/Admin/GestionPedidos/GestionPedidos";
import { GestionProveedores } from "./pages/Auth/Admin/GestionProveedores/GestionProveedores";
import { GestionFamilia } from "./pages/Auth/Admin/GestionFamilias/GestionFamilias";
import { CrearPedidoCliente } from "./pages/Auth/Admin/CrearPedidoCliente/CrearPedidoCliente";
// import serviceWorker from "./serviceWorker";
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Hubot Sans',
      src: " url('./fonts/Hubot_Sans/Hubot Sans/Hubot-Sans.woff2') format('woff2 supports variations')"
      
    },
  },
});

export default function App(){
    const { userStatus } = useContext(DataContext);
    const [user, setUser] = userStatus;
    const [linkToRedirect,setLink] = useState("")
    useEffect(() => {
        let user = window.localStorage.getItem("user");
        user= JSON.parse(user)
        if (user) {
          setUser(user);
          // serviceWorker();
          if(user[0].ID_PERFIL===1){
            setLink("/admin")
          }else if(user[0].ID_PERFIL===2){
            setLink("/cliente")
          }else if(user[0].ID_PERFIL===3){
            setLink("/proveedor")
          }else{
            setLink("/")
          }
        }
      }, [user,setUser]); 

    
     
      function accesHash(){
        console.log("hash")
      }
      
   
    return(
      <ThemeProvider theme={theme}>
        <Routes>
            <Route path="/cliente/:hash" element={<InicioCliente/>}loader={accesHash}/>

            <Route path="/" element={user ? <Navigate to={linkToRedirect} replace /> : <Login />}/>
            <Route path="/register" element={<Register/>}/>

            {/* Auth Pages Cliente */}
            <Route path="/cliente" element={user ?<InicioCliente />:<Navigate to="/" replace /> }/>
            <Route path="/cliente/catalogo" element={user?<Catalogo />:<Navigate to="/" replace /> }/>
            <Route path="/cliente/pedido" element={user?<Carrito />:<Navigate to="/" replace /> }/>
            <Route path="/cliente/producto/:id/:cantidadCarrito?" element={user?<Producto />:<Navigate to="/" replace /> }/>
            <Route path="/cliente/mi-cuenta" element={user?<MiCuenta />:<Navigate to="/" replace /> }/>
            <Route path="/cliente/pedidos" element={user?<MisPedidos/>:<Navigate to="/" replace /> }/>
            <Route path="/cliente/pedidos/detalle/:codigo" element={user?<PedidosDetalles/>:<Navigate to="/" replace /> }/>


            



            {/* Auth Pages Admin */}
            <Route path="/admin" element={user?<Admin />:<Navigate to="/" replace /> }/>
            <Route path="/admin/usuarios" element={user?<Usuarios />:<Navigate to="/" replace /> }/>
            <Route path="/admin/tarifas" element={user?<Tarifas />:<Navigate to="/" replace /> }/>
            <Route path="/admin/tarifas-actualizar/:nombre/:porcentage/:logistica/:id/:vencimiento" element={user?<TarifasActualizar />:<Navigate to="/" replace /> }/>
            <Route path="/admin/articulos/:familia?" element={user?<Articulos />:<Navigate to="/" replace /> }/>
            <Route path="/admin/crear-articulos" element={user?<CrearArticulos />:<Navigate to="/" replace /> }/>
            <Route path="/admin/crear-oferta-especial/:codigo" element={user?<CrearOferta />:<Navigate to="/" replace /> }/>
            <Route path="/admin/gestion-clientes" element={user?<GestionClientes />:<Navigate to="/" replace /> }/>
            <Route path="/admin/gestion-proveedores/:nombre?" element={user?<GestionProveedores />:<Navigate to="/" replace /> }/>
            <Route path="/admin/gestion-grupos-clientes" element={user?<GestionGrupoClientes />:<Navigate to="/" replace /> }/>
            <Route path="/admin/grupos-actualizar/:nombre/:nombreTarifa/:id" element={user?<GestionGrupoClientesActualizar />:<Navigate to="/" replace /> }/>
            <Route path="/admin/pedidos" element={user?<Pedidos />:<Navigate to="/" replace /> }/>
            <Route path="/admin/pedido-detalles/:id" element={user?<DetallesPedido />:<Navigate to="/" replace /> }/>
            <Route path="/admin/gestion-pedidos" element={user?<GestionPedidos />:<Navigate to="/" replace /> }/>
            <Route path="/admin/gestion-familias" element={user?<GestionFamilia />:<Navigate to="/" replace /> }/>
            <Route path="/admin/crear-pedido-cliente" element={user?<CrearPedidoCliente />:<Navigate to="/" replace /> }/>





            {/* Auth Pages Proveedor */}
            <Route path="/proveedor" element={user?<Proveedor />:<Navigate to="/" replace /> }/>
            <Route path="/proveedor/mi-cuenta" element={user?<MiCuentaProveedor />:<Navigate to="/" replace /> }/>



            
             {/* Error 404 */}
            <Route path="*" element={<Error404 />} />
        </Routes>
        </ThemeProvider>
    )

}