import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useState, forwardRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";


import Swal from "sweetalert2";
import "./Form.css";

export const Form = ({
  titulo,
  msg,
  tipoUsuario,
  rowDataClick,
  onCloseForm,
  proveedor,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const handleCreateUser = (data) => {
    data["tipoUsuario"] = tipoUsuario;

    if (rowDataClick) {
      data["id"] = rowDataClick.ID;
    }

    fetch(`${process.env.REACT_APP_API}/portAct${tipoUsuario}.php`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data === "Ok") {
          setOpen(true);
          if (!rowDataClick) {
            reset();
          } else {
            //  onCloseForm()
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error al crear el usuario",
            text: "Por favor, vuelva a intentarlo.",
            confirmButtonColor: "#333",
          });
        }
      });
  };
  const [tarifa, setTarifa] = useState(null);


  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = useState(false);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [tarifas, setTarifas] = useState([]);
  function getTarifas() {
    fetch(`${process.env.REACT_APP_API}/portTarifas.php`)
      .then((data) => data.json())
      .then((data) => {
        setTarifas(data.data);
      });
  }
  useEffect(() => {
    getTarifas();
  }, []);

  useEffect(() => {
    if (rowDataClick) {
      if(rowDataClick.ID_TARIFA){

        setTarifa(rowDataClick.ID_TARIFA);
      }else{
        setTarifa(8)
      }

    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="form-box">
        
        <div className="close-form-btn">
          <AiOutlineCloseCircle onClick={onCloseForm} />
        </div>

        <form className="form" onSubmit={handleSubmit(handleCreateUser)}>
          <span className="title">{titulo}</span>
          <span className="subtitle">
            {rowDataClick ? `Actualizar cuenta para ${tipoUsuario}` : msg}
          </span>
          <div className="form-container-box">
            {errors.nombreComercial?.type === "required" && (
              <p className="error">El nombre comercial es requerido.</p>
            )}
            <label>Nombre Comercial</label>
            <input
              type="text"
              className="input"
              placeholder="Nombre Comercial"
              name="nombreComercial"
              // defaultValue={rowDataClick && rowDataClick.NOMBRE_COMERCIAL||proveedor&&proveedor}
              defaultValue={
                (rowDataClick && rowDataClick.NOMBRE_COMERCIAL) ||
                (proveedor && proveedor)
              }
              {...register("nombreComercial", {
                required: false,
              })}
            />
            {errors.email?.type === "required" && (
              <p className="error">El email es requerido.</p>
            )}
            <label htmlFor="">Email</label>
            <input
              type="email"
              className="input"
              placeholder="Email"
              name="email"
              defaultValue={rowDataClick && rowDataClick.EMAIL}
              {...register("email", {
                required: true,
              })}
            />
            {errors.codigo?.type === "required" && (
              <p className="error">El codigo es requerido.</p>
            )}
            <label>Código</label>

            <input
              type="text"
              className="input"
              placeholder="Código"
              name="codigo"
              defaultValue={rowDataClick && rowDataClick.CODIGO}
              {...register("codigo", {
                required: false,
              })}
            />
            {errors.poblacion?.type === "required" && (
              <p className="error">La población es requerida.</p>
            )}
            <label>Población</label>
            <input
              type="text"
              className="input"
              placeholder="Población"
              name="poblacion"
              defaultValue={rowDataClick && rowDataClick.POBLACION}
              {...register("poblacion", {
                required: false,
              })}
            />
            {errors.provincia?.type === "required" && (
              <p className="error">La provincia es requerida.</p>
            )}
            <label>Provincia</label>

            <input
              type="text"
              className="input"
              placeholder="Provincia"
              name="provincia"
              defaultValue={rowDataClick && rowDataClick.PROVINCIA}
              {...register("provincia", {
                required: false,
              })}
            />
            {errors.web?.type === "required" && (
              <p className="error">La web es requerida.</p>
            )}
            <label>Web</label>

            <input
              type="text"
              className="input"
              placeholder="Web"
              name="web"
              defaultValue={rowDataClick && rowDataClick.WEB}
              {...register("web", {
                required: false,
              })}
            />
            {errors.contacto?.type === "required" && (
              <p className="error">El contacto es requerido.</p>
            )}
            <label>Contacto</label>

            <input
              type="text"
              className="input"
              placeholder="Contacto"
              name="contacto"
              defaultValue={rowDataClick && rowDataClick.CONTACTO}
              {...register("contacto", {
                required: false,
              })}
            />
            {errors.password?.type === "required" && (
              <p className="error">La contraseña es requerida.</p>
            )}
            <label>Contraseña</label>

            <input
              type="text"
              className="input"
              placeholder="Contraseña"
              name="password"
              defaultValue={rowDataClick && rowDataClick.PASSWORD}
              {...register("password", {
                required: false,
              })}
            />
            {errors.direccion?.type === "required" && (
              <p className="error">La dirección es requerida.</p>
            )}
            <label>Dirección</label>

            <input
              type="text"
              className="input"
              placeholder="Dirección"
              name="direccion"
              defaultValue={rowDataClick && rowDataClick.DIRECCION}
              {...register("direccion", {
                required: false,
              })}
            />
            {errors.cPostal?.type === "required" && (
              <p className="error">El código postal es requerido.</p>
            )}
            <label>Código postal</label>

            <input
              type="text"
              className="input"
              placeholder="C.Postal"
              name="cPostal"
              defaultValue={rowDataClick && rowDataClick.CPOSTAL}
              {...register("cPostal", {
                required: false,
              })}
            />
            {errors.tel1?.type === "required" && (
              <p className="error">Al menos un teléfono es requerido.</p>
            )}
            <label>Teléfono-1</label>

            <input
              type="tel"
              className="input"
              placeholder="Teléfono 1"
              name="tel1"
              defaultValue={rowDataClick && rowDataClick.TELEFONO1}
              {...register("tel1", {
                required: true,
              })}
            />
            <label>Teléfono-2</label>

            <input
              type="tel"
              className="input"
              placeholder="Teléfono 2"
              name="tel2"
              defaultValue={rowDataClick && rowDataClick.TELEFONO2}
              {...register("tel2", {
                required: false,
              })}
            />
            {errors.razonSocial?.type === "required" && (
              <p className="error">La razón social es requerida.</p>
            )}
            <label>Razón social</label>

            <input
              type="text"
              className="input"
              placeholder="Razón social"
              name="razonSocial"
              defaultValue={rowDataClick && rowDataClick.RAZON_SOCIAL}
              {...register("razonSocial", {
                required: false,
              })}
            />
            {tipoUsuario === "Proveedores" ? (
              ""
            ) : (
              <select
                name="idTarifa"
                {...register("idTarifa", {
                  required: false,
                })}
                onChange={(e) => setTarifa(e.target.value)}
                value={tarifa}
                // defaultValue={
                //   rowDataClick &&
                //   rowDataClick.ID_TARIFA &&
                //   rowDataClick.ID_TARIFA
                // }
              >
                {tarifas &&
                  tarifas.map((dato) => {
                    return (
                      <option value={dato.ID} key={dato.ID}>
                        {dato.NOMBRE}
                      </option>
                    );
                  })}
              </select>
            )}
            {tipoUsuario === "Proveedores" ? (
              ""
            ) : (
              <>
                <label>VIP</label>
                <input
                  type="checkbox"
                  className="input"
                  name="vip"
                  
                  defaultChecked={rowDataClick && rowDataClick.VIP}
                  {...register("vip", {
                    required: false,
                  })}
                />
              </>
            )}
            {tipoUsuario === "Proveedores" ? (
              <>
                <label>Nombre Transportista</label>

                <input
                  type="text"
                  className="input"
                  placeholder="Nombre"
                  name="nombre_transportista"
                  defaultValue={
                    rowDataClick && rowDataClick.NOMBRE_TRANSPORTISTA
                  }
                  {...register("nombre_transportista", {
                    required: false,
                  })}
                />
                <label>Email Transportista</label>

                <input
                  type="text"
                  className="input"
                  placeholder="Email"
                  name="email_transportista"
                  defaultValue={
                    rowDataClick && rowDataClick.EMAIL_TRANSPORTISTA
                  }
                  {...register("email_transportista", {
                    required: false,
                  })}
                />
                <label>Telf Transportista</label>

                <input
                  type="text"
                  className="input"
                  placeholder="Telf"
                  name="telf_transportista"
                  defaultValue={rowDataClick && rowDataClick.TELF_TRANSPORTISTA}
                  {...register("telf_transportista", {
                    required: false,
                  })}
                />
              </>
            ) : (
              ""
            )}
          </div>

          <button>{rowDataClick ? "Actualizar" : "Registrar"}</button>
        </form>
      </div>

      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {rowDataClick
            ? "Actualizado correctamente"
            : "Registrado correctamente"}
        </Alert>
      </Snackbar>
    </div>
  );
};
