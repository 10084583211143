import { useForm } from "react-hook-form";
import Logo from "../../assets/LogoColor2.png";
import { Link } from "react-router-dom";

import "./Register.css";

export default function Register() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleRegister = (data) => {
    console.log(data);
  };
  return (
    <div className="login-container">
      <form
        className="form-container register-form"
        onSubmit={handleSubmit(handleRegister)}
      >
        <div className="form-header">
          <img loading="lazy" src={Logo} alt="" />
          <span>Servicio de intranet Cerdan</span>
        </div>

        <div className="register-row">
          <div className="register-block">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              name="nombre"
              id="nombre"
              {...register("nombre", {
                required: true,
              })}
            />
             {errors.nombre?.type === "required" && (
            <p className="error">El nombre es requerido.</p>
          )}
          </div>
          <div className="register-block">
            <label htmlFor="apellido">Apellido</label>
            <input
              type="text"
              name="apellido"
              id="apellido"
              {...register("apellido", {
                required: true,
              })}
            />
             {errors.apellido?.type === "required" && (
            <p className="error">El apellido es requerido.</p>
          )}
          </div>
        </div>

        <div className="register-row">
          <div className="register-block">
            <label htmlFor="empresa">Empresa</label>
            <input
              type="text"
              name="empresa"
              id="empresa"
              {...register("empresa", {
                required: true,
              })}
            />
             {errors.empresa?.type === "required" && (
            <p className="error">El nombre de empresa es requerido.</p>
          )}
          </div>
          <div className="register-block">
            <label htmlFor="nif_o_cif">NIF o CIF</label>
            <input
              type="text"
              name="nif_o_cif"
              id="nif_o_cif"
              {...register("nif_o_cif", {
                required: true,
              })}
            />
             {errors.nif_o_cif?.type === "required" && (
            <p className="error">El NIF o CIF es requerido.</p>
          )}
          </div>
        </div>


        <div className="register-row">
          <div className="register-block">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              {...register("email", {
                required: true,
                maxLength: 30,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
              })}
            />
            {errors.email?.type === "required" && (
              <p className="error">El email es requerido.</p>
            )}
            {errors.email?.type === "pattern" && (
              <p className="error">El formato del email es incorrecto.</p>
            )}
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "grey",
                marginTop: "10px",
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              ¿Ya tiene una cuenta? Entrar
            </Link>
          </div>

          <div className="register-block">
            <label htmlFor="telefono">Teléfono</label>
            <input
              type="text"
              name="telefono"
              id="telefono"
              {...register("telefono", {
                required: true,
              })}
            />
            {errors.telefono?.type === "required" && (
              <p className="error">El teléfono es requerido.</p>
            )}
          </div>
          <div className="register-block rol-user" style={{alignItems:"center"}}>
            <label htmlFor="Rol">¿Cómo desea darse de alta?</label>
            <select
              name="Rol"
              id="rol"
              {...register("rol", {
                required: true,
              })}
            >
               
              <option value="2">Cliente</option>
              <option value="3">Proveedor</option>
            </select>
            {errors.rol?.type === "required" && (
            <p className="error">El tipo de usuario es requerido.</p>
          )}
          </div>
        </div>

        <div className="input-row-submit left">
          <input type="submit" value="Registarse" />
        </div>
      </form>
    </div>
  );
}
